import React, { useState } from 'react';
import StandardInputs from '../components/StandardInputs';
import ModeSwitch from '../components/ModeSwitch'; 
import { ToastContainer } from 'react-toastify';
import styles from '../components/styles/LeftPanel.module.css';
import modeStyles from '../components/styles/ModeSwitch.module.css';

const LeftPanel = ({
  mode,
  setMode,
  outputState,
  handleInputChange,
  handleSubmit,
  inputState,
  isSubmitting,
  batchSize,
  setBatchSize,
  isProUser,
}) => {
  const isJobPending =
    outputState.jobs.size > 0 &&
    [...outputState.jobs.values()].some(
      (job) => job.status === 'queued' || job.status === 'processing'
    );

  const isButtonDisabled = isSubmitting;

  const handleInput = (field, value) => {
    handleInputChange(field, value); // Update inputState in App.js
  };

  return (
    <div className={styles.leftPanel}>
      <div className={styles.header}>
        <span>Create Blerst!</span>
      </div>

      {/* Mode Switch */}
      {isProUser && (
        <div className={modeStyles.modeSwitchContainer}>
          <ModeSwitch mode={mode} setMode={setMode} isLoading={isJobPending || isSubmitting} className={styles.loraDropdown} />
        </div>
      )}

      {/* Prompt Input Section */}
      <label className={styles.inputLabel}>Prompt</label>
      <StandardInputs
        prompt={inputState.prompt}
        setPrompt={(value) => handleInput('prompt', value)}
        isLoading={isJobPending}
        mode={mode}
        instructions={inputState.instruction}  // Pass the instruction from inputState
        setInstructions={(value) => handleInput('instruction', value)}  // Update inputState for instruction
      />

      {/* Batch Size Input */}
      <label className={styles.inputLabel}>Number of Images</label>
      <input
        type="number"
        value={batchSize}
        onChange={(e) => setBatchSize(Number(e.target.value))}
        min={1}
        max={10}
        className={styles.numberInput}
      />

      {/* Submit Button */}
      <button
        onClick={handleSubmit}
        disabled={isButtonDisabled}
        className={styles.button}
      >
        {isSubmitting ? 'Queuing...' : 'Create'}
      </button>

      <ToastContainer />
    </div>
  );
};

export default LeftPanel;
