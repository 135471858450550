import React, { useState, useEffect, useRef } from 'react';
import styles from '../components/styles/LeftPanel.module.css';  // Import the CSS module

// Custom hook for typewriter effect
function useTypewriter(text, delay) {
  const [displayedText, setDisplayedText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    if (isTyping && displayedText.length < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText(text.substring(0, displayedText.length + 1));
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [displayedText, text, delay, isTyping]);

  return [displayedText, isTyping, setIsTyping];
}

function StandardInputs({ prompt, setPrompt, isLoading, mode, instructions, setInstructions }) {
  const textareaRef = useRef(null);
  const instructionRef = useRef(null);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  // Placeholder text for the typewriter effect
  const placeholderText = "Prompt here...";

    // Default instructions string
    const defaultInstructions = `Instructions for Writing an Image Prompt:
Write an image prompt for an AI image generator dedicated to creating images of the BLERST character. Each prompt must adhere to the following structure:
    
STRICT RULES:
Character Introduction:
ALWAYS start the prompt with: "Blerst, with vibrant green-yellow skin and two antennae protruding from the top of its head, with three pupils in each eye."

Background Branding:
The background MUST ALWAYS include purple as a dominant element to maintain on-brand color consistency. This can be detailed further based on the setting and scene but must remain within the purple theme.

Style Consistency:
ALWAYS specify that the image must be in a "bold comic style" to ensure uniformity and avoid variations in the quality of generated images.

Facial Expression Control:
Avoid exaggerated or overly weird facial expressions. Ensure that BLERST's face appears consistent, natural, and friendly, without exaggerated or distorted features.

THE PROMPT PYRAMID:
Base of the Pyramid:
Medium: Define the base medium of the image (e.g., "bold, simple comic illustration," "digital painting," etc.). ALWAYS include "bold comic style."
Subject: The subject is ALWAYS the BLERST character. Begin by describing the character with the phrase: "Blerst, with vibrant green-yellow skin and two antennae protruding from the top of its head, with three pupils in each eye."
Activity: Describe the activity BLERST is engaged in, including a general pose and a specific action (e.g., "floating in mid-air," "waving with a friendly expression," "standing with hands on hips").
Setting: Describe the scene where the image takes place. Integrate interesting visual details, ensuring that the background is dominated by purple elements to adhere to branding.
Default Clothing: If no specific wardrobe is requested, describe BLERST in its default outfit: a teal green shirt and dark grey shorts.
Lighting: Specify the type of lighting that enhances the scene (e.g., "bright and clear," "soft with simple shading," "dramatic with stark contrasts").
Vibe: Convey the overall mood or atmosphere of the image, such as "playful and whimsical" or "adventurous and heroic."
Background: Detail the background with purple as the key color. This can be incorporated into elements like the sky, structures, or abstract patterns that maintain the purple theme.
Added Stylistic Details: Add comma-separated specific keywords that enhance the image’s quality or make it more interesting (e.g., "bold outlines, vibrant colors, dynamic poses, sharp but natural facial features" to enhance the comic style).
Point of the Pyramid:
IMPORTANT INSTRUCTIONS:
Describe each region of the image in detail, always ensuring that BLERST's unique characteristics and the purple background theme are highlighted.
Do not include sounds, smells, or other details that cannot be rendered in an image.
Be specific and decisive. Don't use conditional language (e.g., "perhaps red or blue" or "set in a field or forest").
DO NOT generate an image unless asked. Only create the image prompt.
DO NOT prefix the image description with anything that indicates it was requested by the user.
Your response should be formatted exactly as it should be entered into the AI image generation application.`;
  
  // Use the typewriter effect
  const [typewriterText, isTyping, setIsTyping] = useTypewriter(placeholderText, 100);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
    if (instructionRef.current && mode === 'Pro') {
      instructionRef.current.style.height = 'auto';
      instructionRef.current.style.height = `${instructionRef.current.scrollHeight}px`;
    }
  }, [prompt, instructions, mode]);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
    if (!hasStartedTyping) {
      setHasStartedTyping(true);
      setIsTyping(false);  // Stop typewriter effect when user starts typing
    }
  };

  const handleInstructionChange = (e) => {
    setInstructions(e.target.value);
  };

  const handleClick = () => {
    if (!hasStartedTyping) {
      setHasStartedTyping(true);
      setIsTyping(false);  // Stop typewriter effect when the textarea is clicked
    }
  };

  return (
    <div className={styles.typewriterContainer}>
      {/* Typewriter overlay */}
      {!hasStartedTyping && (
        <div className={styles.typewriterOverlay}>
          {typewriterText}
          {isTyping && <span className={styles.blinkingCursor}>|</span>}
        </div>
      )}
      
      {/* Textarea for Prompt */}
      <textarea
        ref={textareaRef}
        value={prompt}
        onChange={handlePromptChange}
        onClick={handleClick}
        className={`${styles.input} ${styles.textareaHiddenPlaceholder} ${isLoading ? styles.loading : ''}`}
        rows="5"
        disabled={isLoading}
      />

      {/* Textarea for Instructions - Only shows in Pro mode */}
      {mode === 'Pro' && (
        <>
          <label className={styles.inputLabel}>Instructions</label>
          <textarea
            ref={instructionRef}
            value={instructions || defaultInstructions} // Set the default value if instructions are empty
            onChange={handleInstructionChange}
            className={`${styles.inputInstruction} ${styles.textareaHiddenPlaceholder} ${isLoading ? styles.loading : ''}`}
            rows="5"
            disabled={isLoading}
          />
        </>
      )}
    </div>
  );
}

export default StandardInputs;
