// Header.js
import React, { useState } from 'react';
import styles from './styles/Header.module.css';
import { loginUser, logoutUser, isAuthenticated, getCurrentAccount } from './Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const Header = ({ user, isAuthenticated, handleLogin, handleLogout }) => {

  // Function to format the address
  const formatAddress = (address) => {
    if (address) {
      return `${address.slice(0, 4)}...${address.slice(-4)}`;
    }
    return '';
  };

  return (
    <div className={styles.header}>
      <a href="http://blerst.com/" className={styles.link} target="_blank" rel="noopener noreferrer">
        <div className={styles.title}>
          <FontAwesomeIcon icon={faHome} className={styles.icon} />
          Home
        </div>
      </a>
      <div className={styles.authContainer}>
        {isAuthenticated ? (
          <>
            <span className={styles.welcomeText}>
              {user.username ? `Welcome, ${user.username}` : `Welcome, ${formatAddress(user.address)}`}
            </span>
            <button onClick={handleLogout} className={styles.button}>Logout</button>
          </>
        ) : (
          <button onClick={handleLogin} className={styles.button}>
            {user.isConnecting ? 'Connecting...' : 'Connect Wallet'}
          </button>
        )}
      </div>
    </div>
  );
}

export default Header;

